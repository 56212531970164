export default {
  // 泰语
  word1001: 'Thai',
  word1002: 'อีเมล',
  word1003: 'รหัสผ่าน',
  word1004: 'ลืมรหัสผ่าน?',
  word1005: 'เข้าสู่ระบบ',
  word1006: 'ลงทะเบียน',
  word1007: 'ข้อตกลงการใช้งานของผู้ใช้',
  word1008: 'ความเป็นส่วนตัวของบุคคล',
  word1009: 'เกี่ยวกับเรา',
  word1010: 'ชื่อผู้ใช้ไม่สามารถเว้นว่างได้',
  word1011: 'รหัสผ่านไม่สามารถเว้นว่างได้',
  word1012: 'หมายเลขบัญชีนี้ถูกระงับการใช้งาน',
  word1013: 'หมายเลขบัญชี หรือ รหัสผ่านไม่ถูกต้อง',
  word1014: 'เข้าสู่ระบบสำเร็จ',
  word1015: 'ชื่อเล่น',
  word1016: 'ป้อนตัวเลขจากด้านขวา',
  word1017: 'รับโค้ดยืนยันตัวตน',
  word1018: 'พิมพ์รหัสผ่านซ้ำ',
  word1019: 'ตัวเลขไม่สามารถเว้นว่าง',
  word1020: 'รหัสยืนยันตัวตนไม่ถูกต้อง',
  word1021: 'mật khẩuที่ป้อนสองครั้งไม่ตรงกัน',
  word1022: 'ชื่อเล่นไม่สามารถว่า ง',
  word1023: 'กล่องจดหมายไม่สามารถว่า งได้',
  word1024: 'ทำซ้ำรหัสผ่านไม่ได้',
  word1025: 'ลองใหม่อีกครั้งในวินาที',
  word1026: 'ลงทะเบียนเรียบร้อยแล้ว',
  word1027: 'รหัสไปรษณีย์',
  word1028: 'ไม่สามารถปล่อยให้เป็นแคปช่าได้',
  word1029: 'รหัสผ่านใหม่',
  word1030: 'แก้ไขรหัสผ่าน',
  word1031: 'รหัสผ่านใหม่ไม่สามารถว่า งได้',
  word1032: 'แก้ไขเรียบร้อยแล้ว',
  word1033: 'ธุรกิจข้ามพรมแดน และระบบการเชื่อมต่อ',
  word1034: 'ระบบบริการ ที่ครอบคลุม',
  word1035: 'บัญชีของคุณถูกปิดการใช้งาน',
  word1036: 'ลงทะเบียน และรับรอง บริษัท',
  word1037: 'ชื่อ บริษัท',
  word1038: 'ที่อยู่ บริษัท',
  word1039: 'โทรศัพท์ บริษัท',
  word1040: 'ตำแหน่งงาน',
  word1041: 'อัพโหลดใบอนุญาตการทำงาน',
  word1042: 'สามารถอัปโหลดแฟ้ม jpg / png ได้เท่านั้น และไม่เกิน 20 MB',
  word1043: 'อัพโหลดหมายเลขภาษี',
  word1044: 'ส่ง',
  word1045: 'ยังไม่มีการตรวจสอบสิทธิ์การเรียกดู',
  word1046: 'แสดงตัวอย่างภาพ',
  word1047: 'กรุณาอัพโหลดใบอนุญาตประกอบการ',
  word1048: 'กรุณาอัพโหลดภาษี',
  word1049: 'ชื่อ บริษัท ไม่สามารถว่า งได้',
  word1050: 'ที่อยู่ บริษัท ไม่สามารถว่า งได้',
  word1051: 'โทรศัพท์ บริษัท ไม่สามารถว่า งได้',
  word1052: 'ไม่มีตำแหน่งว่า ง',
  word1053: 'ลบเรียบร้อยแล้ว',
  word1054: 'ส่งสำเร็จแล้ว',
  word1055: 'ขอโทษนะอีเมลนั้น ลงทะเบียนแล้วใช้อีเมลอื่น',
  word1056: 'การลงทะเบียนไม่ได้รับการรับรองจาก บริษัท',
  word1057:
    'ระบบนี้ พิเศษสำหรับการเชื่อมต่อข้ามพรมแดน และปรับ แต่งให้เหมาะกับธุรกิจเพื่อให้โอกาสทางธุรกิจ และทรัพยากรทางกฎหมายมากขึ้นขอแนะนำให้คุณดำเนินการรับรอง บริษัท เพื่อรับผลประโยชน์เพิ่มเติม',
  word1058: 'คำแนะนำ',
  word1059: 'ยังไม่ได้รับการรับรอง',
  word1060: 'ใบรับรองของ บริษัท',
  word1061: 'มีกล่องจดหมายของผู้ใช้อยู่ หรือถูกลบไปแล้ว',
  word1062: 'รหัสผ่านมีความยาว 8 ถึง 16 ตัวอักษร',
  word1063: 'รูปแบบกล่องจดหมายผิดพลาด',
  word1064: 'รหัสตรวจความถูกต้องของกล่องจดหมายหมดอายุ หรือยังไม่ได้ส่ง',
  word1065: 'รหัสตรวจความถูกต้องของกล่องจดหมาย',

  // 湘企出海系统翻译
  word2001: 'ธุรกิจพบกับประเทศไทย',
  word2002: 'ระบบบริการ ที่ครอบคลุม',
  word2003: 'ระบบเชื่อมต่อธุรกิจข้าม biên giới',
  word2004: 'บัญชีถูกปิดการใช้งาน',

  // 新增
  AccountPlaceholder: 'กล่องจดหมาย',
  AccountEmptyTip: 'บัญชีผู้ใช้กล่องจดหมายไม่สามารถว่า งได้',
  PasswordPlaceholder: 'รหัสผ่าน',
  PasswordEmptyTip: 'รหัสผ่านไม่สามารถว่า งได้',
  AccountPasswordError: 'เลขบัญชี หรือรหัสผ่านผิดพลาด',
  RightNumber: 'กรุณาใส่หมายเลขในภาพทางด้านขวา',
  NumberEmptyTip: 'ตัวเลขในภาพไม่สามารถว่า งได้',
  EmailPlaceholder: 'กล่องจดหมาย',
  GetEmailCode: 'รับรหัสตรวจความถูกต้องของกล่องจดหมาย',
  Retry: ' ลองใหม่อีกครั้งในวินาที',
  Resend: 'ส่งใหม่',
  EmailEmptyTip: 'บัญชีผู้ใช้กล่องจดหมายไม่สามารถว่า งได้',
  EmailCodePlaceholder: 'รหัสไปรษณีย์',
  EmailCodeEmptyTip: 'รหัสตรวจความถูกต้องของกล่องจดหมายไม่สามารถว่า งได้',
  InputNewPassword: 'โปรดป้อนรหัสผ่านใหม่',
  AgainInputNewPassword: 'โปรดป้อนรหัสผ่านใหม่อีกครั้ง',
  AgainInputPassword: 'กรุณาป้อนรหัสผ่านอีกครั้ง',
  Confirm: 'แน่ใจ',
  RealName: 'ชื่อจริง',
  RealNameEmptyTip: 'ชื่อจริงไม่สามารถว่า งได้',
  EmailCodeInvalid: 'รหัสตรวจความถูกต้องของกล่องจดหมายถูกต้องโปรดเรียกใหม่อีกครั้ง',
  RegisterCertify: 'ลงทะเบียน และรับรอง',
  NotCertifyRegister: 'ยังไม่ได้รับการรับรอง และลงทะเบียนโดยตรง',
  NotCertifyTip:
    'ระบบนี้ พิเศษสำหรับการเชื่อมต่อข้ามพรมแดน และแนะนำให้คุณทำการรับรองธุรกิจเพื่อรับโอกาสทางธุรกิจ และทรัพยากรทางกฎหมายเพิ่มเติม',
  GoCertify: 'กำลังมุ่งหน้าไปยังการรับรอง',
  FileSize: 'กรุณาอัปโหลดเจพีจี หรือรูปแบบของพีเอ็นจี และขนาดไฟล์ไม่ควรเกิน 20 MB',
  AccountInexistence: 'บัญชีไม่มีอยู่ และไม่สามารถแก้ไขรหัสผ่านได้',
  DeleteTip: 'บัญชีของคุณถูกลบไปแล้ว กรุณาติดต่อผู้ดูแลระบบของสภาการค้าของคุณ',
  DearUser: 'เรียนผู้ใช้ ',
  NotShow: 'ไม่แสดงอีกต่อไป',
  PromptContentOne:
    'สวัสดีระบบนี้ เป็นระบบ IT ที่สร้างขึ้นสำหรับธุรกิจของประเทศไทยทั้งภายใน และภายนอก และภายในประเทศรวมถึงทรัพยากรทางกฎหมาย ที่ใช้ในธุรกิจ[ประเทศไทย]เหมาะสำหรับผู้ถือหุ้นทั่วไปหัวหน้าแผนกการตลาดหัวหน้าฝ่ายการตลาดหัวหน้าฝ่ายขาย และหัวหน้าฝ่ายยุติธรรมของกระทรวงยุติธรรมโปรดยืนยันว่า หน่วยของคุณอยู่ภายในเมือง[ประเทศไทย]ก่อน ที่จะลงทะเบียน และกรอกข้อมูลส่วนบุคคลของคุณตามความเป็นจริงระบบการจัดการเบื้องหลังของเราจะหยุด หรือลบบัญชี ที่เกี่ยวข้องกับธุรกิจ ที่ไม่ใช่[ประเทศไทย]โปรดทราบ',
  QuickPaymentTip:
    'สมาคมพาณิชย์ของท่านได้ตั้งค่าการชำระเงินค่าสมาชิกแล้ว โปรดชำระโดยเร็ว ไม่งั้นคุณอาจมีผลกระทบในวันที่ [วันที่เฉพาะ]',
  UnableLoginTip: 'ไม่สามารถเข้าสู่ระบบได้',
  Prompt: 'คำแนะนำ',
  MembershipDues: 'จ่ายค่าสหกรณ์',
  Determine: 'ยืนยัน',
  MembershipExpirationTip: 'สถานะสมาชิกของคุณหมดอายุแล้ว โปรดเสียค่าสมาชิกเพื่อต่ออายุ',
  Cancel: 'ยกเลิก',
  ImgOnly: 'รูปแบบภาพต้องเป็น jpeg、jpg、png、bmp、tiff เท่านั้น',
  PictureSize2MBTip: 'ขนาดรูปภาพไม่ควรเกิน 2MB',
  UploadSuccess: 'อัปโหลดสำเร็จ'
}
