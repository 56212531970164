export default {
  // 老挝语
  word1001: 'Lao',
  word1002: 'ອີເມວ',
  word1003: 'ລະຫັດຜ່ານ',
  word1004: 'ກັບລະຫັດຜ່ານ?',
  word1005: 'ເຂົ້າສູ່ລະບົບ',
  word1006: 'ສະທິກໃຈ',
  word1007: 'ການຕິດຕັ້ງຂອງຜູ້ໃຊ້',
  word1008: 'ການປົກປ້ອງຄວາມເປັນສ່ວນຕິດຕາມຂອງຜູ້ໃຊ້',
  word1009: 'ກ່ຽວກັບພວກເຮົາ',
  word1010: 'ລະຫັດຜູ້ໃຊ້ບໍ່ສາມາດຖືກປ້ອງໂຕ',
  word1011: 'ລະຫັດຜ່ານບໍ່ສາມາດຖືກປ້ອງໂຕ',
  word1012: 'ປະເພດບັນຊີຖືກປິດແລ້ວ',
  word1013: 'ລະຫັດຜ່ານ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ',
  word1014: 'ສືບຕໍ່ເຂົ້າສູ່ລະບົບສຳເລັດ',
  word1015: 'ນັກໃຈ',
  word1016: 'ກະລຸນາປ້ອນຕົວເລືອກທີ່ຢູ່ໃຕ້',
  word1017: 'ສົ່ງຮັບການຢືນຢັນລະຫັດ',
  word1018: 'ລະຫັດຜ່ານທັງສອງ',
  word1019: 'ຕົວເລືອກບໍ່ສາມາດຖືກຄ້ອງໄວ້',
  word1020: 'ການຢືນຢັນລະຫັດບໍ່ຖືກຕ້ອງ',
  word1021: 'ລະຫັດຜ່ານທັງສອງບໍ່ຖືກຕົວເລືອກ',
  word1022: 'ນັກສະແດງບໍ່ສາມາດຖືກປ້ອງໄວ້',
  word1023: 'ໂທລະສາຍບໍ່ສາມາດຖືກປ້ອງໄວ້',
  word1024: 'ລະຫັດຜ່ານຈົບຄັ້ງທີ່ 2 ບໍ່ສາມາດຖືກປ້ອງໄວ້',
  word1025: 'ກະລຸນາເຮັດໃຫ້ຄິດເພື່ອລະບົບຂຶ້ນໃໝ່ໃນປີ້ທີ່',
  word1026: 'ສະເພາະລົງທະບຽນສຳເລັດ',
  word1027: 'ກັບຢູ່ອີເມລ (email) ຂະແຍນຢືນຢັນ',
  word1028: 'ກະລຸນາປ້ອງໃສ່ການຢືນຢັນຂໍ້ຄວາມ',
  word1029: 'ລະຫັດຜ່ານໃໝ່',
  word1030: 'ຕໍ່ຕົວປະກັນລະຫັດຜ່ານ',
  word1031: 'ລະຫັດຜ່ານໃໝ່ບໍ່ສາມາດປ້ອງໄວ້',
  word1032: 'ຕໍ່ຕົວປະກັນສຳເລັດ',
  word1033: 'ເລກບໍລິສັດເກິງແຫ່ງການຄ້າເສຍທາງ & ສະຖານທີ່ຕິດຕັ້ງການຕິດຕາມລາວ',
  word1034: 'ຫລູກຂອງປະຊຸມບໍລິສັດທັງໝົດ',
  word1035: 'ລະຫັດຜູ້ໃຊ້ຂອງທ່ານໄດ້ຖືກປິດໃຊ້',
  word1036: 'ບັນທຶກແລະເຮັດການຢືນຢັນການຕິດຕາມຂອງບໍລິສັດ',
  word1037: 'ຊື່ບໍລິສັດ',
  word1038: 'ກາບໂທລະສັບບໍລິສັດ',
  word1039: 'ໂທລະສັບບໍລິສັດ',
  word1040: 'ຕຳແໜ່ງທີ່ຢູ່ພັດທະນາ',
  word1041: 'ອັບເດດຮູບພາບການຢືນຢັນບໍລິສັດ',
  word1042: 'ການອັບເດດໄດ້ຮຽບຮ້ອງພາຍຸfile type jpg/png ແລະບໍ່ຕັ້ງຄ່າຫຼາຍກວ່າ 20MB',
  word1043: 'ອັບເດດປະກັນຈ່າຍ paj',
  word1044: 'ບັນທຶກ',
  word1045: 'ບໍ່ຢືນຢັນປຽບໃຕ້, ເຂົ້າອີເລວກ່ອນ',
  word1046: 'ການເຫັນພື້ນຖານວິເສແລະຮູບພາບ',
  word1047: 'ກະລຸນາອັບເດດປະກັນເປີດໂຮງຮຽນ',
  word1048: 'ກະລຸນາອັບເດດປະກັນຈ່າຍ paj',
  word1049: 'ຊື່ບໍລິສັດບໍ່ສາມາດຫ້າມໄດ້',
  word1050: 'ກາບໂທລະສັບບໍລິສັດບໍ່ສາມາດຫ້າມໄດ້',
  word1051: 'ເບີໂທລະສັບຂອງ ກໍລະກົດບໍ່ຄວນປະຫຍາຍ',
  word1052: 'ບໍລິສັດທີ່ຢູ່ໃຊ້ບໍ່ຄວນຖືກປະຫຍາຍ',
  word1053: 'ລົບສຳເລັດ',
  word1054: 'ສົ່ງເຂົ້າສຳເລັດ',
  word1055: 'ຂອບໃຈ, ເພື່ອນີ້ໄດ້ຖືກลงทะเบียนແລ້ວ, ກະລຸນາໃຊ້ອີເມວອື່ນ',
  word1056: 'ບັນທຶກຕອນນີ້ບໍ່ໄດ້ກັບການຢືນຢັນບໍລິສັດ',
  word1057:
    'ສistemນີ້ຖືກຕ້ອງສ້າງສຳລັບການປະກາດກັບປະເທດ, ທີ່ຖືກຕ້ອງສົ່ງແບບຕິດຕາມສ່ວນການບໍລິສັດ ແລະ ສະຫນັບສະຫນູນໂຄງການທີ່ສູງສຸດ ແລະ ສິ່ງທີ່ຕ້ອງການກັບການສຶກສາ. ພວກເຮົາແນະນໍາໃຫ້ທ່ານຢືນຢັນບໍລິສັດເພື່ອຮັບການສູງສຸດຂອງການໃຊ້.',
  word1058: 'ຄະແນນ',
  word1059: 'ບໍ່ຢືນຢັນຕອນນີ້',
  word1060: 'ຢືນຢັນບໍລິສັດ',
  word1061: 'ອີເມວຜູ້ໃຊ້ແລະຖືກລົບແລ້ວ',
  word1062: 'ຕົວເລກສະຫຼຸບパス워드ຕ້ອງປະຕິບັດຈາກ 8 ຫາ 16 ນັກກົດລະຄັນ',
  word1063: 'ອີເມວຜູ້ໃຊ້ມີບໍ່ຖືກຕ້ອງ',
  word1064: 'ກྡgear codeອີເມວເປັນປີເດີດ ຫຼື ບໍ່ໄດ້ຖືກສົ່ງ',
  word1065: 'ກྡgear codeອີເມວຜິດ.',

  // 湘企出海系统翻译
  word2001: 'ການເຄື່ອງຮ້າຍຈະພົບທະຫລວງໄທ',
  word2002: 'ເລກບໍລິສັດພວກຫຼຸດຊັງສາຕັ້ງແຕ່',
  word2003: 'ລະບົບການປະມວນການເຄື່ອງຮ້າຍແຕ່ລະດັບສິ່ງທີ່ຈະຊ່ຽວຊານປະເທດ',
  word2004: 'ປະຊຸມນີ້ຖືກປິດໃຊ້',

  // 新增
  AccountPlaceholder: 'ອີເມວ',
  AccountEmptyTip: 'ປະຊຸມອີເມວບໍ່ສາມາດຖືກປະຫຍາຍ',
  PasswordPlaceholder: 'ປະຊຸມ',
  PasswordEmptyTip: 'ປະຊຸມບໍ່ສາມາດຖືກປະຫຍາຍ',
  AccountPasswordError: 'ປະຊຸມຫຼືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ',
  RightNumber: 'ກະລຸນາປ້ອນຕຳແໜ່ງຕັ້ງຢູ່ຮ່າງເທິງຂໍ້ມູນຮູບ',
  NumberEmptyTip: 'ຕຳແໜ່ງຕັ້ງຢູ່ຮ່າງຮູບບໍ່ສາມາດຖືກປະຫຍາຍ',
  EmailPlaceholder: 'ອີເມວ',
  GetEmailCode: 'ສົ່ງອີເມວຢືນຢັນ',
  Retry: ' ລະເມີນອີ່ຕໍ່ໄປ (ປີເດີດ) ຫຼື ທີ່ຈະເຮັດກັບຄືນ',
  Resend: 'ກັບຄືນສົ່ງ',
  EmailEmptyTip: 'ປະຊຸມອີເມວບໍ່ສາມາດຖືກປະຫຍາຍ',
  EmailCodePlaceholder: 'ກྡgear codeອີເມວ',
  EmailCodeEmptyTip: 'ກྡgear codeອີເມວບໍ່ສາມາດຖືກປະຫຍາຍ',
  InputNewPassword: 'ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່',
  AgainInputNewPassword: 'ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່ອີກຄັ້ງ',
  AgainInputPassword: 'ກະລຸນາປ້ອນລະຫັດຜ່ານອີກຄັ້ງ',
  Confirm: 'ສົ່ງແລ້ວ',
  RealName: 'ຊື່ທີ່ຈັດເຈັບ',
  RealNameEmptyTip: 'ຊື່ທີ່ຈັດເຈັບບໍ່ສາມາດຖືກປະຫຍາຍ',
  EmailCodeInvalid: 'ກྡgear codeອີເມວບໍ່ຖືກຕ້ອງ, ກະລຸນາຮັບອີເມວຢືນຢັນອີກຄັ້ງ',
  RegisterCertify: 'ບັນທຶກແລະການຢືນຢັນ',
  NotCertifyRegister: 'ບໍ່ຢືນຢັນຕອນນີ້, ລົງທະບຽນໄດ້',
  NotCertifyTip:
    'ສລະບົບນີ້ສົງໄສພື້ນຖານທີ່ຈະການປະກາດກັບປະເທດ, ພວກເຮົາແນະນໍາໃຫ້ທ່ານການຢືນຢັນບໍລິສັດເພື່ອຮັບໄດ້ການສູງສຸດຂອງການຊົງເຂົ້າຮ່ວມແລະສິ່ງທີ່ຕ້ອງການກັບການສຶກສາ.',
  GoCertify: 'ສົ່ງໄປການຢືນຢັນ',
  FileSize: 'ກະລຸນາໂയ്ക്ക-up fail ຂອງຮູບແບບ .jpg ຫຼື .png, ແລະຂະເທດສຳຫຼວດວ່າ ອີເມວຜູ້ໂຍກເຕີມບໍ່ຄວນຫຼາຍກວ່າ 20MB.',
  AccountInexistence: 'ປະຊຸມນີ້ບໍ່ມີ, ຄວນບໍ່ສາມາດປ່ຽນລະຫັດຜ່ານ.',
  DeleteTip: 'ຂອງທ່ານຖືກລົບແລ້ວ. ກະລຸນາຕິດຕໍ່ຜູ້ຈັດການຂອງກະຊວງຄວາມຄ້າສິນຄ້າຂອງທ່ານ.',
  DearUser: 'ລືບຄຸມລະບົບ,',
  NotShow: 'ບໍ່ສະແດງອີກ',
  PromptContentOne:
    'ສະບາຍດີ, ລະບົບນີ້ແມ່ນ ລະບົບນິເວດວິທະຍາ ທີ່ສໍາລັບ ບ ບໍລິສັດ ຫລື ອົງການນິເວດວິທະຍາ .ມັນປະກອບດ້ວຍຂໍ້ມູນເພື່ອເຂົ້າຮ່ວມຢູ່ໃນປະເທດສະມາຊິກຊ່ອງ ແລະ ພ່າງປະເທດສະມາຊິກຕ່າງໆ ເພື່ອນໍາໃຊ້ກັບທຸລະກິດຢ່າງປະເທດສະມາຊິນ. ມັນຍົງດ້ວຍສ ກະລຸນາຮັບຮູ້ວ່າ ບໍ່ຮູ້ຈັດກ່ຽວກັບເຈົ້າຂອງທ່ານເຮືອນແກ້ວ [ປະເທດຟີລິກາ] ແລະ ສິດທີ່ຈະເຕີມຂໍ້ມູນທ່ານເຮືອນກ່ອນກ່ອນສຳຮອງໄວ້ ລະບົບ ທີ່ຄຳບຄຸມລະບົບຄຳບຄຸມຄູ່ສົນທະນາໄວ້ນັ້ນຖືກປ້ອງກັນ ຫຼື ລົບທຳອັບບໍລິສັດທຳອັນທີ່ບໍ່ແມ່ນເຈົ້າ. ກະລຸນາໃ',
  QuickPaymentTip:
    'Camera comerces ຂອງທ່ານໄດ້ຕັ້ງຄ່າຈ່າຍສັງຄົມ. ກະລຸນາຈ່າຍໃຫ້ໄວ້ເທົ່ານັ້ນ, ບໍ່ແມ່ນທ່ານຈະມີຜົນກະທົບກັນຈາກ [ວັນທີ່ນັ້ນ]',
  UnableLoginTip: 'ບໍ່ສາມາດເຂົ້າລະບົບໄດ້',
  Prompt: 'ຄຳເຕືອນ',
  MembershipDues: 'ກ່ຽວກັບຈ່າຍຄ່າສັງຄົມ',
  Determine: 'ກົດແທກ',
  MembershipExpirationTip: 'Chamber of commerce ຂອງທ່ານໄດ້ສິ້ນສຸດ. ກະລຸນາຈ່າຍຄ່າສັງຄົມເພື່ອການປັບປຸງ.',
  Cancel: 'ລຶບ',
  ImgOnly: 'ຮູບແບບຮູບພາບສາມາດເປັນ jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'ຂະໜາດຮູບພາບບໍ່ຄວນເກີນ 2MB',
  UploadSuccess: 'ອັບໂຫຼດສຳເລັດ'
}
