export default {
  // 马来语
  word1001: 'Malay',
  word1002: 'Emel',
  word1003: 'Kata Laluan',
  word1004: 'Lupa Kata Laluan?',
  word1005: 'Log Masuk',
  word1006: 'Daftar',
  word1007: 'Perjanjian Pengguna',
  word1008: 'Privasi Individu',
  word1009: 'Mengenai Kami',
  word1010: 'Akaun tidak boleh kosong',
  word1011: 'Kata laluan tidak boleh kosong',
  word1012: 'Akaun telah dibekukan',
  word1013: 'Nombor akaun atau kata laluan salah',
  word1014: 'Berjaya log masuk',
  word1015: 'Nama panggilan',
  word1016: 'Masukkan nombor dari sebelah kanan',
  word1017: 'Dapatkan kod verifikasi',
  word1018: 'Ulangi',
  word1019: 'Ruang bagi nombor tidak boleh kosong',
  word1020: 'Kod verifikasi tidak betul',
  word1021: 'Kedua-dua kata laluan tidak serupa',
  word1022: 'Nama samaran tidak boleh kosong',
  word1023: 'Emel tidak boleh kosong',
  word1024: 'Ulangi kata laluan tidak boleh kosong',
  word1025: 'Cuba lagi dalam beberapa saat',
  word1026: 'Pendaftaran berjaya',
  word1027: 'Kod verifikasi emel',
  word1028: 'Kod verifikasi tidak boleh kosong',
  word1029: 'Kata laluan baru',
  word1030: 'Tukar kata laluan',
  word1031: 'Kata laluan baru tidak boleh kosong',
  word1032: 'Kemaskini berjaya',
  word1033: 'Sistem Pematuhan Syarikat & Perundangan Antarabangsa',
  word1034: 'Sistem Perkhidmatan Bersama',
  word1035: 'Akaun anda telah dilumpuhkan',
  word1036: 'Daftar dan sertifikasikan syarikat anda',
  word1037: 'Nama syarikat',
  word1038: 'Alamat syarikat',
  word1039: 'Nombor telefon syarikat',
  word1040: 'Jawatan yang sedang dipegang',
  word1041: 'Muat naik lesen perniagaan',
  word1042: 'hanya boleh memuat naik fail JPG atau PNG, dan saiz tidak melebihi 20MB',
  word1043: 'Muat naik surat kelulusan cukai',
  word1044: 'Hantar',
  word1045: 'Terus melihat tanpa verifikasi terlebih dahulu',
  word1046: 'Pratinjau gambar',
  word1047: 'Sila muat naik lesen perniagaan anda',
  word1048: 'Sila muat naik surat kelulusan cukai',
  word1049: 'Ruangan nama syarikat tidak boleh kosong',
  word1050: 'Ruangan alamat syarikat tidak boleh kosong',
  word1051: 'Nombor telefon syarikat tidak boleh kosong',
  word1052: 'Jawatan semasa tidak boleh kosong',
  word1053: 'Berjaya dihapuskan',
  word1054: 'Berjaya diserahkan',
  word1055: 'Maaf, emel tersebut telah didaftarkan. Sila gunakan emel lain.',
  word1056: 'Pendaftaran sementara tidak melibatkan pengesahan syarikat',
  word1057:
    'Sistem ini khusus dirancang untuk pertukaran lintas sempadan dan ditailkan bagi setiap syarikat, menyediakan peluang perniagaan yang lebih banyak dan sumber-sumber undang-undang. Kami cadangkan anda mengesahkan syarikat untuk mendapat lebih banyak hak ke atas sistem.',
  word1058: 'Amanah',
  word1059: 'Tidak melakukan pengesahan sekarang',
  word1060: 'Pengesahan syarikat',
  word1061: 'Emel pengguna sudah wujud atau telah dihapuskan',
  word1062: 'Panjang kata laluan perlu berada dalam 8 hingga 16 aksara',
  word1063: 'Format emel salah',
  word1064: 'Kod verifikasi emel telah tamat tempoh atau belum dihantar',
  word1065: 'Kod verifikasi emel tidak betul',

  // 湘企出海系统翻译
  word2001: 'Perniagaan Bertemu Thailand',
  word2002: 'Sistem Perkhidmatan Bersama',
  word2003: 'Sistem Pemautan Antarabangsa Perdagangan',
  word2004: 'Akaun ini telah dilumpuhkan',

  // 新增
  AccountPlaceholder: 'E-mel',
  AccountEmptyTip: 'Ruang bagi akaun e-mel tidak boleh kosong',
  PasswordPlaceholder: 'Kata Laluan',
  PasswordEmptyTip: 'Ruang bagi kata laluan tidak boleh kosong',
  AccountPasswordError: 'Nombor akaun atau kata laluan salah',
  RightNumber: 'Masukkan nombor yang terdapat dalam imej sebelah kanan',
  NumberEmptyTip: 'Nombor dalam imej tidak boleh kosong',
  EmailPlaceholder: 'E-mel',
  GetEmailCode: 'Dapatkan kod verifikasi e-mel',
  Retry: ' Cuba semula dalam {detik} saat',
  Resend: 'Hantar semula',
  EmailEmptyTip: 'Ruang bagi akaun e-mel tidak boleh kosong',
  EmailCodePlaceholder: 'Kod verifikasi e-mel',
  EmailCodeEmptyTip: 'Kod verifikasi e-mel tidak boleh kosong',
  InputNewPassword: 'Sila masukkan kata laluan baru',
  AgainInputNewPassword: 'Sila masukkan semula kata laluan baru',
  AgainInputPassword: 'Sila masukkan semula kata laluan anda',
  Confirm: 'Setuju',
  RealName: 'Nama sebenar',
  RealNameEmptyTip: 'Nama sebenar tidak boleh kosong',
  EmailCodeInvalid: 'Kod verifikasi e-mel tidak sah, sila muat turun semula.',
  RegisterCertify: 'Daftar dan lakukan pengesahan',
  NotCertifyRegister: 'Tidak mahu mengesahkan, daftar terus',
  NotCertifyTip:
    'Sistem ini dikhususkan untuk pertukaran lintas sempadan, kami cadangkan anda melakukan pengesahan syarikat untuk menikmati lebih banyak peluang perniagaan dan sumber-sumber undang-undang.',
  GoCertify: 'Ke arah Pengesahan',
  FileSize: 'Sila muat naik fail dalam format .jpg atau .png, dan saiz fail tidak patut melebihi 20MB.',
  AccountInexistence: 'Akaun tersebut tiada, tidak dapat menukarkan kata laluan.',
  DeleteTip: 'Akaun anda telah dihapus. Sila hubungi pentadbir kesatuan dagang anda.',
  DearUser: 'Yang Mulia Pengguna,',
  NotShow: 'Tidak akan ditampilkan lagi',
  PromptContentOne:
    'Halo, sistem ini adalah sistem IT yang direka untuk syarikat di Thailand. Ia menyempurnakan maklumat projek dan sumber undang-undang di dalam dan luar Thailand untuk digunakan oleh syarikat Thailand. Ia sesuai untuk pemegang saham, pengurus umum, kepala jabatan pemasaran, kepala jabatan jualan, kepala jabatan pembelian, dan kepala jabatan undang-undang di Thailand untuk digunakan. Sila sahkan bahawa alamat terdaftar anda berada dalam [Thailand] dan benar-benar mengisi maklumat peribadi anda sebelum mendaftar. Sistem pengurusan bahagian belakang kami akan membekukan atau menghapuskan akaun berkaitan syarikat bukan Thailand. Sila maklumkan.',
  QuickPaymentTip:
    'Majlis perdagangan anda telah menetapkan yuran keahlian. Sila bayar dengan segera, jika tidak, anda akan menghadapi kesan pada [tarikh tertentu]',
  UnableLoginTip: 'Tidak dapat log masuk',
  Prompt: 'Notifikasi',
  MembershipDues: 'Bayar yuran keahlian',
  Determine: 'Sahkan',
  MembershipExpirationTip: 'Keahlian anda telah tamat. Sila buat pembaruan dengan membayar yuran keahlian',
  Cancel: 'Batalkan',
  ImgOnly: 'Format imej hanya boleh menjadi jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'Saiz imej tidak boleh melebihi 2MB',
  UploadSuccess: 'Muat naik berjaya'
}
